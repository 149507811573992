import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import './Header.css';

class Header extends Component {
    switchLang() {
        const lang = i18next.language;
        if (lang === 'fr') {
            i18next.changeLanguage('en');
        } else {
            i18next.changeLanguage('fr');
        }
    }
    render() {
        const { t } = this.props;
        let switchLangButton;

        if (i18next.language === 'en') {
            switchLangButton = <img src="/img/french_flag.png" onClick={this.switchLang} className="flag" alt="frenchFlag"/>
        } else {
            switchLangButton = <img src="/img/uk_flag.png" onClick={this.switchLang} className="flag" alt="star6"/>
        }
        return (
        <header className="header-section" id="header-section">
        <div className="shape-area">
            <img src="/img/shapes/shape-1.png" className="shape-1" alt="shape1"/>
            <img src="/img/shapes/shape-2.png" className="shape-2" alt="shape2"/>
            <img src="/img/shapes/shape-3.png" className="shape-3" alt="shape3"/>
            <img src="/img/shapes/shape-4.png" className="shape-4" alt="shape4"/>
            <img src="/img/shapes/shape-5.png" className="shape-5" alt="shape5"/>

            <img src="/img/shapes/star-1.png" className="shape-6" alt="star1"/>
            <img src="/img/shapes/star-1.png" className="shape-7" alt="star2"/>
            <img src="/img/shapes/star-1.png" className="star shape-8" alt="star3"/>
            <img src="/img/shapes/star-1.png" className="star shape-9" alt="star4"/>
            <img src="/img/shapes/star-1.png" className="star shape-10" alt="star5"/>
            <img src="/img/shapes/star-1.png" className="star shape-11" alt="star6"/>
        </div>
        <nav id="navbar" className="navbar navbar-expand-lg navbar-light ginbo-navbar">
            <div className="container">

                <div className="logo-section">
                    <a className="logo-title navbar-brand" href='/'><img src="/logo128.png" alt="img-logo"/></a>
                </div>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="icofont-navigation-menu"></i>
                </button>
                <div className="collapse navbar-collapse nav-main justify-content-end" id="navbarNav">
                    <ul className="navbar-nav" id="primary-menu">
                        <li className="nav-item active">
                            <a className="nav-link active" href="#header-section">{t('Home')}
                                <span className="sr-only">(current)</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#about-ginbo-section">{t('About')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#features-section">{t('Features')}</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#contact-us-section">Contact</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://app.fleepi.com/">{t('Users space')}</a>
                        </li>
                    </ul>
                </div>
                <div className="right-side-box">
                    {switchLangButton}
                </div>
            </div>
        </nav>
    </header>
    );
  }
}
 
export default withTranslation()(Header);

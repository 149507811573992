import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

class Timeline1 extends Component {
  render() {
    const { t } = this.props;
    return (
        <section className="features-section" id="features-section" style={{"background-color": "#2d64bb"}}>
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-9">
                    <div className="features-text">
                        <h2 className="features-subtitle">{t('Timeline')}</h2>
                        <h3 className="features-description">{t('where')}</h3>
                    </div>
                </div>
            </div>
            <Timeline position="alternate">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent  sx={{ py: '15px', px: 2 }} color='white'>
        <Typography variant="h5" component="span">
        {t('Teamcréation')}
          </Typography>
          <Typography>
          {t('Text1')}
          </Typography>
          </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '15px', px: 2 }} color='white'>
        <Typography variant="h5" component="span">
        {t('Testlearn')}
          </Typography>
          <Typography>
          {t('Text2')}
          </Typography>
          </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '15px', px: 2 }} color='white'>
        <Typography variant="h5" component="span">
        {t('Managementprocesses')}
          </Typography>
          <Typography>
          {t('Text3')} 
          </Typography>
      </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '15px', px: 2 }} color='white' >
        <Typography variant="h5" component="span">
        11/2021 - 01/2022 - Fast Forward
          </Typography>
          <Typography>
          {t('Text4')}
          </Typography>
          </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '15px', px: 2 }} color='white'>
        <Typography variant="h5" component="span">
        02/2022 - 05/2022 - Beta & Growth Hacking
          </Typography>
          <Typography>
          {t('Text5')}
          </Typography>
          </TimelineContent>
      </TimelineItem>
    </Timeline>
        </div>
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-9">
                    <div className="features-text">
                        <h3 className="features-subtitle">{t('After')}</h3>
                    </div>
                </div>
            </div>
            <Timeline position="alternate">
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent  sx={{ py: '15px', px: 2 }} color='white'>
        <Typography variant="h5" component="span">
        06/2022 - 09/2022 - Consolidation
          </Typography>
          <Typography>
          {t('Text6')}
          </Typography>
          </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '15px', px: 2 }} color='white'>
        <Typography variant="h5" component="span">
       04/2021 - 06/2021 - Test & Learn
          </Typography>
          <Typography>
          {t('Text7')}
          </Typography>
          </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '15px', px: 2 }} color='white'>
        <Typography variant="h5" component="span">
        10/2022 - 12/2022 - Launch & Metrics
          </Typography>
          <Typography>
          {t('Text8')}
          </Typography>
      </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '15px', px: 2 }} color='white' >
        <Typography variant="h5" component="span">
        {t('Title')}
          </Typography>
          <Typography>
          {t('Text9')}
          </Typography>
          </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '15px', px: 2 }} color='white'>
        <Typography variant="h5" component="span">
        {t('Title2')}
          </Typography>
          <Typography>
          {t('Text10')}
          </Typography>
          </TimelineContent>
      </TimelineItem>
    </Timeline>
        </div>
    </section>
    );
  }
}
 
export default withTranslation()(Timeline1);
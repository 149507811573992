import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class BannerTop extends Component {
  render() {
    const { t } = this.props;
    return (
    <section className="banner-section">
        <div className="overlay">
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="banner-text">
                            <h2>{t('Discover')} <span>Fleepi</span></h2>
                            <p>{t('Land Text')}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="stores-button">
                            <div className="mockup">
                                <img src="img/mockup.png" alt="mockup"/>
                            </div>
                            <a href="https://playstore.com/"><img src="img/play-store.png" alt="playstore-img"/></a>
                            <a href="https://appstore.com/"><img src="img/app-store.png" alt="appstore-img"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
  }
}
 
export default withTranslation()(BannerTop);
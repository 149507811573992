import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header';
import Contact from './Contact';
import Footer from './Footer';
import Content from './Content';
import BannerTop from './BannerTop';
import About from './About';
import Newsletter from './Newsletter';
import TeamShowcase from './TeamShowcase';
import Timeline1 from './Timeline';


export class Home extends Component {
	constructor() {
		super();
		this.state = {email: ''};

		this.handleChange = this.handleChange.bind(this);
	}
	handleChange(event) {
		this.setState({email: event.target.value});
	}

	render() {
		return (
			<div>
			<Header />
			<BannerTop />
			<About />
			<Content />
			<TeamShowcase />
			<Timeline1/>
			{/* <Faq /> */}
			<Newsletter />
			<Contact />
			<Footer />
			</div>
		);
	}
}

/* <div className="App">
<div style={{marginBottom: "0.25rem"}}>
	<h1 className="fleepi-color" style={style.logoTitle}>Fleepi</h1>
</div>
<p style={{fontWeight: "normal", fontSize: 10, marginTop: 0, marginBottom:20}}>COMING SOON</p>
<form style={style.newsletterForm} action="https://usebasin.com/f/599b4d238e64" method="POST">
	<label className="fleepi-color" style={style.newsletterLabel}>Get notified when we launch</label>
	<input type="email" name="email" style={style.newsletterInput} placeholder="Type your email address here" value={this.state.email} onChange={this.handleChange}/>
	<button type="submit" className="newsletterButton" disabled={!this.state.email}>Notify me</button>
</form>
<div className='icons'>
	<FontAwesomeIcon className='icon' icon={faTwitterSquare} onClick={this.newPageUrl.bind(this, 'https://twitter.com/fleepiapp')} />
	<FontAwesomeIcon className='icon' icon={faInstagramSquare} onClick={this.newPageUrl.bind(this, 'https://www.instagram.com/fleepiapp/')} />
	<FontAwesomeIcon className='icon' icon={faFacebookSquare} onClick={this.newPageUrl.bind(this, 'https://www.facebook.com/Fleepi-112674830572824')} />
	<FontAwesomeIcon className='icon' icon={faLinkedin} onClick={this.newPageUrl.bind(this, 'https://www.linkedin.com/company/fleepi/')} />
</div>
</div> */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faInstagramSquare, faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { withTranslation } from 'react-i18next';

import './Footer.css';

class Footer extends Component {
    newPageUrl(url) {
		window.open(url);
	}
  render() {
    const { t } = this.props;
    return (
        <footer className="footer-section">
        <div className="overlay">
            <div className="footer-content">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <div className="footer-area">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2 d-flex justify-content-xm-center">
                                        <div className="footer-left">
                                            <ul className="footer-menu">
                                                <li className="footer-item">
                                                    <a className="footer-link" href='/'><img src="logo128.png" alt="logo"/></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-10 col-md-10 col-sm-10 d-flex justify-content-end justify-content-xm-center">
                                        <div className="footer-right">
                                            <ul className="footer-menu">
                                                <li className="footer-item">
                                                    <a className="footer-link" href='/'>{t('Home')}</a>
                                                </li>
                                                <li className="footer-item">
                                                    <a className="footer-link" href='/'>{t('Data Politics')}</a>
                                                </li>
                                                <li className="footer-item">
                                                    <a className="footer-link" href='/'>{t('Terms Of Use')}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom-area">
                        <div className="row">

                            <div className="col-lg-6 col-md-6 col-sm-7 d-flex justify-content-xm-center">
                                <div className="footer-bottom">
                                    <p>Copyright © 2022 All Rights Reserved By<a href="/">FLEEPI.</a></p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-5 d-flex justify-content-end justify-content-xm-center">
                                <div className="social-icon">
                                    <ul className="icon-area">
                                        <li className="social-footer">
                                            <FontAwesomeIcon
                                            className='icon'
                                            icon={faTwitterSquare}
                                            onClick={this.newPageUrl.bind(this, 'https://twitter.com/fleepiapp')} />
                                        </li>
                                        <li className="social-footer">
                                            <FontAwesomeIcon
                                            className='icon'
                                            icon={faLinkedin}
                                            onClick={this.newPageUrl.bind(this, 'https://www.linkedin.com/company/fleepi/')} />
                                        </li>
                                        <li className="social-footer">
                                            <FontAwesomeIcon
                                            className='icon'
                                            icon={faFacebookSquare}
                                            onClick={this.newPageUrl.bind(this, 'https://www.facebook.com/Fleepi-112674830572824')} />
                                        </li>
                                        <li className="social-footer">
                                            <FontAwesomeIcon
                                            className='icon'
                                            color="white"
                                            icon={faInstagramSquare}
                                            onClick={this.newPageUrl.bind(this, 'https://www.instagram.com/fleepiapp/')} />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    );
  }
}
 
export default withTranslation()(Footer);
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';

import './Contact.css';

class Contact extends Component {
    handleSubmit = event => {
        event.preventDefault();
        const body = {
            name: this.refs.name.value,
            email: this.refs.email.value,
            subject: this.refs.subject.value,
            message: this.refs.message.value
        };

        axios.post(`https://fleepi-api.herokuapp.com/contact`, body)
        .then(res => {
          console.log(res);
          console.log(res.data);
        })
    }
  render() {
    const { t } = this.props;
    return (
        <section className="contact-us-section" id="contact-us-section">
            <div className="row">
                <div className="col-lg-12">
                    <div className="contact-us-area">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 text-center">
                                <div className="contact-us-text">
                                    <h5 className="contact-us-title">{t('Need Help')}</h5>
                                    <h2 className="contact-us-subtitle">{t('Contact Us')}</h2>
                                    <p className="contact-us-title-describe">{t('Answer Shortly')}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="contact-form">
                                    <form id="contactForm" onSubmit={this.handleSubmit} className="contact-form-aqua">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <h3>{t('Name')}*</h3>
                                                <input type="text" ref="name" name="name" required="" placeholder="Entrez votre nom complet" autoComplete="off" className="contact-frm active"/>
                                            </div>
                                            <div className="col-lg-6">
                                                <h3>E-mail*</h3>
                                                <input type="email" ref="email" name="email" required="" placeholder="Entrez votre adresse e-mail" autoComplete="off" className="contact-frm"/>
                                            </div>
                                        </div>
                                        <h3>{t('Subject')}*</h3>
                                        <input type="text" name="name" ref="subject" required="" placeholder="Tapez votre sujet ici" autoComplete="off" className="contact-frm"/>
                                        <h3>Message*</h3>
                                        <textarea name="message" ref="message" id="message" placeholder="Tapez votre message ici" className="contact-msg"></textarea>
                                        <div className="btn-submit">
                                            <input id="form-submit" type="submit" value="ENVOYER" className="contact-btn"/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }
}
 
export default withTranslation()(Contact);
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class About extends Component {
  render() {
    const { t } = this.props;
    return (
        <section className="about-ginbo-section navigation" id="about-ginbo-section">
        <div className="about-ginbo-area navigation">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-6 d-flex align-items-center justify-content-center">
                        <div className="about-left">
                            <div className="video-box">
                                <div className="icon-box">
                                    <a href="https://www.youtube.com/watch?v=mxcP0IyFcso" className="video-play-btn popup-video"><img src="img/play-icon.png" alt="yt-video"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="about-right">
                            <div className="about-text">
                                <h2 className="about-title">{t('About Title')}</h2>
                                <h2 className="about-subtitle">{t('About Subtitle')}</h2>
                                <p className="about-description">{t('About Description')}</p>
                            </div>
                            <div className="check-point">
                                <p><i className="icofont-check-circled"></i>{t('Essential Documents')}</p>
                                <p><i className="icofont-check-circled"></i>{t('Check in')}</p>
                                <p><i className="icofont-check-circled"></i>{t('Alerts System')}</p>
                                <p><i className="icofont-check-circled"></i>{t('Delay Alert')}</p>
                                <p><i className="icofont-check-circled"></i>{t('Money Management')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
  }
}
 
export default withTranslation()(About);
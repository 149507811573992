import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import BastienImg from "../assets/moreau.bastien.jpg";
import ClaraImg from "../assets/clara.hottier.jpg";
import ArthurImg from "../assets/arthur.mariet.jpg";
import ThomasImg from "../assets/thomas.boda.jpg";
import ThierryImg from "../assets/thierry.masumbuko.jpg";
import BenjaminImg from "../assets/benjamin.fiorina.jpg";
import NicolasImg from "../assets/nicolas.zona@epitech.eu.jpg";

class TeamShowcase extends Component {
  render() {
    const { t } = this.props;
    return (
        <section className="features-section" id="features-section" style={{"background-color": "#2d50bb"}}>
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-9">
                    <div className="features-text">
                        <h2 className="features-subtitle">{t('DiscoverTeam')}</h2>
                        <p className="features-description">{t('DiscoverTeam2')}</p>
                    </div>
                </div>
            </div>
            <div className="single-item-top">
                <div className="row mb-60-none">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box" >
                                    <img style={{
                                        "height": "100%",
                                        "width": "100%",
                                        "border-radius": "100%",
                                        "object-fit": "fill",
                                    }} src={ThomasImg} alt="thomas-img" />
                                </div>
                            </div>
                            <h3>Thomas Boda</h3>
                            <p>{t('Mobiledeveloper')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <img style={{
                                        "height": "100%",
                                        "width": "100%",
                                        "border-radius": "100%",
                                        "object-fit": "fill",
                                    }} src={ArthurImg} alt="arthur-img" />
                                </div>
                            </div>
                            <h3>Arthur Mariet</h3>
                            <p>{t('Webdeveloper')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <img style={{
                                        "height": "100%",
                                        "width": "100%",
                                        "border-radius": "100%",
                                        "object-fit": "fill",
                                    }} src={NicolasImg} alt="nicolas-img" />
                                </div>
                            </div>
                            <h3>Nicolas Zona</h3>
                            <p>{t('Webdeveloper')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <img style={{
                                        "height": "100%",
                                        "width": "100%",
                                        "border-radius": "100%",
                                        "object-fit": "fill",
                                    }} src={ThierryImg} alt="thierry-img" />
                                </div>
                            </div>
                            <h3>Thierry Masumbuko</h3>
                            <p>{t('Backdeveloper')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <img style={{
                                        "height": "100%",
                                        "width": "100%",
                                        "border-radius": "100%",
                                        "object-fit": "fill",
                                    }} src={BenjaminImg} alt="benjamin-img" />
                                </div>
                            </div>
                            <h3>Benjamin Fiorina</h3>
                            <p>{t('Mobiledeveloper')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <img style={{
                                        "height": "100%",
                                        "width": "100%",
                                        "border-radius": "100%",
                                        "object-fit": "fill",
                                    }} src={ClaraImg} alt="clara-img" />
                                </div>
                            </div>
                            <h3>Clara Hottier</h3>
                            <p>{t('Webdeveloper')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6" style={{"margin": "0 auto"}}>
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <img style={{
                                        "height": "100%",
                                        "width": "100%",
                                        "border-radius": "100%",
                                        "object-fit": "fill",
                                    }} src={BastienImg} alt="bastien-img" />
                                </div>
                            </div>
                            <h3>Bastien Moreau</h3>
                            <p>{t('Backdeveloper')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
  }
}
 
export default withTranslation()(TeamShowcase);
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Newsletter extends Component {
  render() {
    const { t } = this.props;
    return (
        <section className="subscribe-section">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="subscribe-text">
                        <h2 className="subscribe-title">{t('Stay With Us')}</h2>
                        <h2 className="subscribe-subtitle">{t('Register Newsletter')}</h2>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-lg-8">
                    <form className="subscribe" action="https://usebasin.com/f/599b4d238e64" method="POST">
                        <input type="email" name="email" id="email" placeholder="E-mail" autoComplete="off" className="input-subscribe"/>
                        <button type="submit" className="subscribe-btn">{t('Register')}</button>
                    </form>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </div>
            </div>
        </div>
    </section>
    );
  }
}

export default withTranslation()(Newsletter);
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Content extends Component {
  render() {
    const { t } = this.props;
    return (
        <section className="features-section" id="features-section">
        <div className="container">
            <div className="row d-flex justify-content-center">
                <div className="col-lg-9">
                    <div className="features-text">
                        <h2 className="features-subtitle">{t('Discover Fleepi')}</h2>
                        <p className="features-description">{t('Impress')}</p>
                    </div>
                </div>
            </div>
            <div className="single-item-top">
                <div className="row mb-60-none">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <i class="icofont-iphone"></i>
                                </div>
                            </div>
                            <h3>{t('Mobile')}</h3>
                            <p>{t('Android/Ios')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <i class="icofont-web"></i>
                                </div>
                            </div>
                            <h3>{t('Web App')}</h3>
                            <p>{t('Web App Description')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <i class="icofont-ui-flight"></i>
                                </div>
                            </div>
                            <h3>{t('Flight Company')}</h3>
                            <p>{t('Flight Company Description')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <i class="icofont-train-line"></i>
                                </div>
                            </div>
                            <h3>{t('Train Support')}</h3>
                            <p>{t('Train Support Description')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <i class="icofont-hotel"></i>
                                </div>
                            </div>
                            <h3>{t('Suggestions')}</h3>
                            <p>{t('Suggestions Description')}</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <div className="single-about-box">
                            <div className="icon-box-outer">
                                <div className="icon-box">
                                    <i class="icofont-megaphone"></i>
                                </div>
                            </div>
                            <h3>{t('Change Tracking')}</h3>
                            <p>{t('Change Tracking Description')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
  }
}
 
export default withTranslation()(Content);